import React, { Component } from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import mainImg from "../../images/services/coach-hire-services-corporate-events.jpg"
import NewsStrip from "../../components/news-strip"
import arrowSvg from "../../images/arrow-circle-right-solid.svg"

class ServicePage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Coach hire for corporate events" description="Coach hire for corporate events by Tetley's Coaches - Leeds' oldest bus and coach company established 1949." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Corporate and Events</h1>
            </div>

            <div className="news-article-body">

              <p>Tetley's Coaches has responded to meet the expectations of corporate coach hire clients by heavily investing in new executive level coaches. These vehicles are top specification with high comfort seating, onboard WC, flat screen LCD monitors and air conditioning as standard.</p>
              <p>Our friendly and experienced sales team will be happy to discuss your itinerary and tailor your coach hire requirements and provide a no-obligation, no-pressure competitive quote.</p>
              <p>
                <img className="service-img" src={mainImg} alt="Coach hire for corporate events" />
              </p>
              <p>Whether you are transporting clients or colleagues to a day at the races, an office party, a corporate event or a conference, Tetley's coaches can supply your transport. When booking with Tetley's Coaches, you are speaking directly to the operator and not through a booking agent.</p>
              <p>We have wide experience in multiple vehicle transfers and can provide a dedicated point of contact to manage your transport requirements. We work with a number of events organisers and a large proportion of our work is repeat business. It's a testament to our customer care, our experience, our quality coaches and our staff.</p>
              <p>If you're looking for quality and experience, we're the coach hire company to call. Established in 1949, Tetley's Coaches have provided comfortable, safe and reliable coach transport for over 75 Years.</p>
              <p className="text-center">
                <a className='btn btn-grow btn-quote' href="https://portal.tetleyscoaches.co.uk/Quotation/NewQuotation" target="_blank" rel="noopener noreferrer">
                  <span className='btn-quote__text'>Get quote</span>
                  <img className='btn-quote__icon' src={arrowSvg} alt="" />
                </a>
              </p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default ServicePage
